import { Box } from "@xstyled/styled-components";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import S from "./book-this-tour-button.style";

const BookThisTourButton = ({ tourId }) => (
    <Box row pt={0} px={4} pb={5} justifyContent="center">
        <Link to={`/contact?tour=${tourId}`}>
            <S.button variant="contained" color="primary" size="large">
                Book this tour
            </S.button>
        </Link>
    </Box>
);

BookThisTourButton.propTypes = {
    tourId: PropTypes.string.isRequired,
};

export default BookThisTourButton;
