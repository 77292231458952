import styled from "@xstyled/styled-components";
import { space } from "@xstyled/system";

import { tableStyle } from "../../../style/typography";

const Table = styled.table`
    ${tableStyle};
    ${space};
`;

export default Table;
