import { Button } from "@material-ui/core";
import styled from "@xstyled/styled-components";

const S = {};

S.button = styled(Button)`
    font-size: 4;
    line-height: 1;
    padding: 3 4;
    text-transform: none;
`;

export default S;
