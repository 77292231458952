import styled, { Box } from "@xstyled/styled-components";

const S = styled(Box)`
    position: relative;
    border-radius: 8;
    padding: 4;
    box-shadow: regular;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export default S;
