import styled from "@xstyled/styled-components";
import { Link } from "gatsby";

import { linkStyle } from "../../../style/typography";

const BasicLink = styled(Link)`
    ${linkStyle}
`;

export default BasicLink;
