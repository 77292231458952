import { Box } from "@xstyled/styled-components";
import PropTypes from "prop-types";
import React from "react";

import BasicLink from "../../../common/BasicLink/basic-link";
import Headline from "../../../typography/Headline/headline";
import Paragraph from "../../../typography/Paragraph/paragraph";
import Table from "../../../typography/Table/table";
import S from "./tour-rate-card.style";

const TourRateCard = ({ type }) => (
    <S>
        {type === "regular" && (
            <Box row mx={-4} mb={-4}>
                <Box col={{ xs: 1, md: 1 / 2 }} px={4} pb={4}>
                    <Headline as="h3" variant="h3" mb={3}>
                        Classic Tour <small>(3 hours)</small>
                    </Headline>
                    <Table>
                        <tbody>
                            <tr>
                                <td>1 adult</td>
                                <Box as="td" textAlign="right">
                                    <strong>150€</strong>
                                </Box>
                            </tr>
                            <tr>
                                <td>2/3 adults</td>
                                <Box as="td" textAlign="right">
                                    <strong>250€</strong>
                                </Box>
                            </tr>
                            <tr>
                                <td>4/5 adults</td>
                                <Box as="td" textAlign="right">
                                    <strong>350€</strong>
                                </Box>
                            </tr>
                            <tr>
                                <td>For each additional hour</td>
                                <Box as="td" textAlign="right">
                                    <strong>60€</strong>
                                </Box>
                            </tr>
                            <tr>
                                <td>For each additional person</td>
                                <Box as="td" textAlign="right">
                                    <strong>75€</strong>
                                </Box>
                            </tr>
                        </tbody>
                    </Table>
                </Box>
                <Box col={{ xs: 1, md: 1 / 2 }} px={4} pb={4}>
                    <Headline as="h3" variant="h3" mb={3}>
                        Full Day Tour <small>(7 hours)</small>
                    </Headline>
                    <Paragraph>
                        Please, <BasicLink to="/contact">contact me</BasicLink>{" "}
                        for a quote.
                    </Paragraph>
                </Box>
                <Box col={1} px={4} pb={4}>
                    <Headline as="h3" variant="h3" mb={3}>
                        Discount
                    </Headline>
                    <Paragraph>For a combination of 2 or more tours.</Paragraph>
                </Box>
            </Box>
        )}
        {type === "custom" && (
            <Box>
                <Headline as="h3" variant="h3" mb={3}>
                    Rate
                </Headline>
                <Paragraph>
                    The rate for this tour depends on the time required for its
                    preparation. Last minute requests rarely work.
                </Paragraph>
            </Box>
        )}
    </S>
);

TourRateCard.propTypes = {
    type: PropTypes.oneOf(["regular", "custom"]),
};

TourRateCard.defaultProps = {
    type: "regular",
};

export default TourRateCard;
