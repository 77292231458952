import { Box } from "@xstyled/styled-components";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import Hero from "../../components/common/Hero/hero";
import ContainerNarrow from "../../components/layout/ContainerNarrow/container-narrow";
import Layout from "../../components/layout/Main/main";
import SEO from "../../components/seo";
import BookThisTourButton from "../../components/templates/tour/BookThisTourButton/book-this-tour-button";
import TourPushEnd from "../../components/templates/tour/TourPushEnd/tour-push-end";
import TourRateCard from "../../components/templates/tour/TourRateCard/tour-rate-card";
import Headline from "../../components/typography/Headline/headline";

const Tour = ({ data }) => {
    const { markdownRemark: tour } = data;
    return (
        <Layout>
            <SEO
                title={tour.frontmatter.title}
                description={tour.frontmatter.description}
                image={tour.frontmatter.featuredImage.publicURL}
            />
            <Box mb={5}>
                <Hero
                    fluid={tour.frontmatter.featuredImage.childImageSharp.fluid}
                />
            </Box>
            <ContainerNarrow>
                <Box>
                    <Headline variant="h2">{tour.frontmatter.title}</Headline>
                </Box>
                <Box mb={5}>
                    <Headline as="h2" variant="h3">
                        {tour.frontmatter.subTitle}
                    </Headline>
                </Box>
                <Box
                    mb={5}
                    textAlign="justify"
                    className="template-tour"
                    dangerouslySetInnerHTML={{ __html: tour.html }}
                />
                <Box mb={3}>
                    <BookThisTourButton tourId={tour.frontmatter.id} />
                </Box>
                <Box mb={5}>
                    <TourRateCard type={tour.frontmatter.rate} />
                </Box>
                <Box mb={5}>
                    <TourPushEnd />
                </Box>
            </ContainerNarrow>
        </Layout>
    );
};

Tour.propTypes = {
    data: PropTypes.object,
};

export const pageQuery = graphql`
    query TourArticleByPath($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                featuredImage {
                    publicURL
                    childImageSharp {
                        fluid(maxWidth: 2000) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                id
                rate
                subTitle
                description
                title
            }
            html
        }
    }
`;

export default Tour;
