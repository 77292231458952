import styled, { keyframes } from "@xstyled/styled-components";
import GatsbyImage from "gatsby-image";

const animation = keyframes`
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.3);
    }
`;

const S = styled.div`
    position: relative;
    overflow: hidden;
    height: calc(100vh - 106px);
    width: 100%;
`;

S.picture = styled(GatsbyImage)`
    height: calc(100vh - 106px);
    transform: translateY(0);
    position: relative;

    & img {
        transform: scale(1.3);
        object-fit: cover;
        animation: ${animation} 20s 1;
        animation-fill-mode: forwards;
    }
`;

export default S;
