import PropTypes from "prop-types";
import React from "react";

import S from "./hero.style";

const Hero = ({ title, fluid }) => (
    <S>
        <S.picture alt={title} fluid={fluid} />
    </S>
);

Hero.propTypes = {
    title: PropTypes.string,
    fluid: PropTypes.object,
};

export default Hero;
